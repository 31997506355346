import React from 'react';
import { Auth } from 'aws-amplify';
import { useTranslation } from 'react-i18next';

export const SignOut = ({ onSignOut }: { onSignOut?: Function }) => {
    const { t } = useTranslation();
    const handleClick = async (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        if (typeof onSignOut === 'function') {
            await onSignOut();
        }
        await Auth.signOut();
        window.location.reload();
    };

    return (
        <div>
            <a style={{ cursor: "pointer" }} onClick={handleClick}>
                <u>{t('logOut')}</u>
            </a>
        </div>
    )
};