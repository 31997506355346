// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const CaseStatus = {
  "DECISION_AVAILABLE": "DecisionAvailable",
  "NOT_COMPLETE": "NotComplete"
};

const LoanFileStatus = {
  "IMPORTED": "Imported",
  "IN_PROGRESS": "InProgress"
};

const CreditReportStatus = {
  "IMPORTED": "Imported",
  "IN_PROGRESS": "InProgress"
};

const UserGroup = {
  "ADMIN": "Admin",
  "COUNSELOR": "Counselor"
};

const Program = {
  "SONYMA": "SONYMA"
};

const { Organization, CognitoUser, RentalPayment, Asset, IncomeItem, Party, LoanFile, CreditReport, AusDecision, Case } = initSchema(schema);

export {
  Organization,
  CognitoUser,
  RentalPayment,
  Asset,
  IncomeItem,
  Party,
  LoanFile,
  CreditReport,
  AusDecision,
  Case,
  CaseStatus,
  LoanFileStatus,
  CreditReportStatus,
  UserGroup,
  Program
};