import React, { Suspense, lazy } from "react";
import { Container } from 'semantic-ui-react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { MainHeader } from '../../components/mainHeader';
import { MainFooter } from '../../components/mainFooter';

const Cases = lazy(() => import('../case/cases'));
const EditCase = lazy(() => import('../case/editCase'));

export const Home = () => {
    return (
        <Container fluid style={{ paddingLeft: 200, paddingRight: 200, paddingTop: 20, height: '100vh' }}>
            <Suspense fallback={<div>Loading data...</div>}>
                <Router>
                    <MainHeader/>
                    <Switch>
                        <Route path="/case/:id/:tab?">
                            <EditCase/>
                        </Route>
                        <Route path="/">
                            <Cases/>
                        </Route>
                    </Switch>
                    <MainFooter/>
                </Router>
            </Suspense>
        </Container>
    )
}