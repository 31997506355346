import React from 'react';
import { Divider, Container, Header, Grid } from 'semantic-ui-react'

export const MainFooter = () => (
    <Container style={{
        position: 'absolute',
        bottom: 0,
        width: '100%',
        left: 0,
        paddingBottom: '20px'
    }}>
        <Divider hidden clearing/>
        <Grid>
            <Grid.Column only="computer tablet">
                <Container textAlign="center">
                    <Header as="h5">
                        Center For NYC Neighborhoods - Stronger homeowners. Stronger communities.
                    </Header>
                </Container>
            </Grid.Column>
        </Grid>
    </Container>
)